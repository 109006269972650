import {ButtonHTMLAttributes, useContext, useEffect, useMemo, useRef} from "react";
import styles from './FancyButton.module.scss';
import {AudioContext, AudioEffectsContext} from "../AudioEffectsContext";
import {useEventListener} from "../../utils";

type FancyButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    color?: string;
}
const noop = () => {};

const resetAudio = (a: HTMLAudioElement) => {
    a.currentTime = 0;
    a.pause();
    return a;
}

export const FancyButton = ({className, color, onMouseDown = noop, onMouseUp = noop, onClick = noop, ...props}: FancyButtonProps) => {
    const audio = useContext(AudioContext);
    const aDown = useMemo(() => new Audio(URL.createObjectURL(audio.samples['click-down.mp3'])), []);
    const aUp = useMemo(() => new Audio(URL.createObjectURL(audio.samples['click-up.mp3'])), []);

    const isPressed = useRef(false);
    const handleMouseDown = () => {
        resetAudio(aDown).play();
        isPressed.current = true;
    }
    const handleMouseUp = () => {
        if (!isPressed.current) {
            return
        }
        resetAudio(aUp).play();
        isPressed.current = false;
    }



    useEventListener(window, 'mouseup', handleMouseUp);


    return <div
        onMouseDown={onMouseDown as any}
        onMouseUp={onMouseUp as any}
        onClick={onClick as any}
    >
        <button
            onMouseDown={handleMouseDown}
            {...props}
            className={`${className}
         ${styles.btn}
          ${styles[`btn-${color}`]}`}
        />
    </div>;
}