import {createContext, PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import {selectCurrentBlind, selectLevel} from "../store/counterSlice";

const sampleNames = [
    '1f.mp3',
    '1m.mp3',
    '2f.mp3',
    '2m.mp3',
    '3f.mp3',
    '3m.mp3',
    '4m.mp3',
    '5m.mp3',
    '6m.mp3',
    '7m.mp3',
    '8m.mp3',
    '9m.mp3',
    '10m.mp3',
    '11m.mp3',
    '12m.mp3',
    '13m.mp3',
    '14m.mp3',
    '15m.mp3',
    '16m.mp3',
    '17m.mp3',
    '18m.mp3',
    '19m.mp3',
    '20m.mp3',
    '30m.mp3',
    '40m.mp3',
    '50m.mp3',
    '60m.mp3',
    '70m.mp3',
    '80m.mp3',
    '90m.mp3',
    '100m.mp3',
    '200m.mp3',
    '300m.mp3',
    '400m.mp3',
    '500m.mp3',
    '600m.mp3',
    '700m.mp3',
    '800m.mp3',
    '900m.mp3',
    '1000f2-3.mp3',
    '1000f5p.mp3',
    '1000m.mp3',
    '1_5m.mp3',
    'bid.mp3',
    'round-number.mp3',
    'click-down.mp3',
    'click-up.mp3',
] as const;


type Samples = typeof sampleNames[number];


const dozensToSamples = (n: [number, number]) => [`${n[1]}${n[0]}m.mp3` as Samples];
const onesToSamples = (t: [number, number, number]) => {
    const res: Samples[] = [];

    if (t[1] === 1) {
        res.push(...dozensToSamples([t[0], t[1]]))
    } else if(t[0]) {
        res.push(`${t[0]}m.mp3` as Samples)
    }
    if(t[1] !== 1 && t[1]) {
        res.push(`${t[1]}0m.mp3` as Samples)
    }

    if(t[2]) {
        res.push(`${t[2]}00m.mp3` as Samples)
    }

    return res;
};
const thousandsToSamples = (t: [number, number, number]) => {
    const res: Samples[] = [];

    if (t[1] === 1) {
        res.push(...dozensToSamples([t[0], t[1]]))
    } else if(t[0] === 1 && !t[1] && !t[2]) {

    } else if(t[0]) {
        if ([1, 2].indexOf(t[0]) !== -1) {
            res.push(`${t[0]}f.mp3` as Samples);
        } else {
            res.push(`${t[0]}m.mp3` as Samples);
        }
    }
    if(t[1] !== 1 && t[1]) {
        res.push(`${t[1]}0m.mp3` as Samples)
    }

    if(t[2]) {
        res.push(`${t[2]}00m.mp3` as Samples)
    }

    switch (t[0]) {
        case 1:
            res.unshift(`1000m.mp3` as Samples);
            break;
        case 2:
        case 3:
        case 4:
            res.unshift(`1000f2-3.mp3` as Samples);
            break;
        default:
            res.unshift(`1000f5p.mp3` as Samples);
            break;
    }

    return res;
};

const tripletToSamples = (t: [number, number, number], ti: number): Samples[] => {
    let res: Samples[] = [];
    switch (ti) {
        case 0:
            res = onesToSamples(t)
            break;
        case 1:
            res = thousandsToSamples(t)
            break;
        default:
    }

    return res;
}
const numToTriplets = (n: number) => {
    const res: [number, number, number][] = [];
    const s = n.toString().split('').reverse().join('');

    for (let i = 0; i < s.length; ++i) {
        const ti = Math.floor(i / 3);
        const t = res[ti] || [];
        t.push(Number(s[i]));
        res[ti] = t;
    }

    return res;
}
const numberToSamplesBid = (number: number): Samples[] => {
    const triples = numToTriplets(number);
    const samples = triples
        .map((t, i) => tripletToSamples(t, i))
        .flatMap(t => t)
        .reverse();

    if (number === 1500) {
        return ['1_5m.mp3'/*, '1000f2-3.mp3'*/];
    }

    return samples;
};

export const AudioContext = createContext({
    sampleNames,
    samples: null as any as Record<Samples, Blob>,
})

export const AudioEffectsContext = ({children}: PropsWithChildren) => {
    const [samples, setSamples] = useState<Record<Samples, Blob>>({} as any);

    // грузим все семплы
    useEffect(() => {
        (async () => {
            const samples: Record<Samples, Blob> = {} as any;
            for (const sample of sampleNames) {
                const data = await fetch(`/audio/${sample}`);
                samples[sample] = await data.blob();
            }
            setSamples(samples);
        })();
    }, []);

    return <>
        {Object.entries(samples).length && <AudioContext.Provider value={{sampleNames, samples}}>
            {children}
        </AudioContext.Provider>}
    </>;
}
export const AudioEffects = () => {
    const {samples} = useContext(AudioContext);
    const player = useRef<HTMLAudioElement | null>(null);
    const level = useSelector(selectLevel);
    const blind = useSelector(selectCurrentBlind);

    const [chain, setChain] = useState<Samples[]>([]);

    const pushChain = (newLinks: Samples[]) => setChain(chain => [/*...chain, */...newLinks])
    const popChain = () => setChain(chain => {
        const newChain = [...chain];

        newChain.shift();

        return newChain;
    })

    // если левел поменялся воспроизводим
    useEffect(() => {
        if (!player.current || !samples['1f.mp3']) {
            return;
        }

        pushChain([
            'round-number.mp3', ...numberToSamplesBid(level + 1),
            'bid.mp3', ...numberToSamplesBid(blind.sb), ...numberToSamplesBid(blind.bb),
        ]);
    }, [level, player.current]);

    // перебираем семплы в цепочке воспроизведения
    useEffect(() => {
        console.log(chain)

        if (!player.current) {
            return;
        }
        const sample = chain[0];
        if (!samples[sample]) {
            return;
        }

        player.current.src = URL.createObjectURL(samples[sample]);
        player.current.play();
    }, [chain[0]?.toString() + chain.length.toString()]);


    const playSamplesChain = () => {
        if (!player.current || !samples['1f.mp3']) {
            return;
        }
        player.current.play();
    };

    const handlePlayEnd = () => popChain();

    return createPortal(<audio
        onCanPlay={playSamplesChain}
        onEnded={handlePlayEnd}
        ref={player}
    />, document.body)
}